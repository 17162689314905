import React, { useEffect, useState, useCallback, useMemo } from "react";
import BackButton from "../../component/BackButton/BackButton";
import Icon from "../../component/Icon/Icon";
import SquareImage from "../../component/Image/SquareImage";
import { NOTFOUND } from "../../helpers/constant";
import ThemeLayout from "../../layout/themeLayout";
const NotFound = React.memo((props) => {
    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-md-8 text-center p-5">
                                <div className="d-block">
                                    <BackButton />
                                </div>
                                <SquareImage className="my-4" src={NOTFOUND} width={"200px"} />
                                <h3>
                                    {" "}
                                    <Icon name="info-circle text-info" /> This page isn't available
                                </h3>
                                <p>The followed link may be broken or have been removed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
});

export default NotFound;

