import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";

const BackButton = () => {
    const history = useHistory();

    const goBack = (e) => {
        e.preventDefault();

        if (history.length > 2) {
            history.goBack();
        } else {
            history.push("/");
        }
    };

    return (
        <>
            <span onClick={goBack} className="btn btn-sm p-0 bck-btn text-left">
                <Icon className="cursor-pointer" name="circled-left text-info mr-2" />
                <span className="cursor-pointer text-info">Go {history.length > 2 ? "Back" : " to Home"}</span>
            </span>
            {/* <Button type="button" onClick={goBack}>
                Go {history.length > 2 ? "Back" : " to Home"}
            </Button> */}
        </>
    );
};

export default BackButton;
